import React from 'react';
import { Link } from 'gatsby';
import './style.scss';
import { DiGithubBadge } from 'react-icons/di';
import { IoGlobe } from 'react-icons/io5';
import { ButtonProps } from '../../../utilities/types';

const index: React.FC<ButtonProps> = (props) => {
  const { id, linkTo, textButton, icon, scrollToSection } = props;
  const showIcon =
    icon && icon === 'github' ? (
      <DiGithubBadge
        style={{
          fontSize: '24px',
        }}
      />
    ) : (
      <IoGlobe
        style={{
          fontSize: '24px',
        }}
      />
    );

  return (
    <>
      {linkTo ? (
        <Link
          to={linkTo}
          target="_blank"
          rel="noopener noreferrer"
          className="btn_reset-link"
        >
          <button id={id} className="btn" style={{ padding: '0.5rem 1.5rem' }}>
            {icon && showIcon}
            {textButton}
          </button>
        </Link>
      ) : (
        <button id={id} className="btn" onClick={scrollToSection?.('contact')}>
          {textButton}
        </button>
      )}
    </>
  );
};

export default index;
