import React, { useEffect, useState } from 'react';
import './style.scss';
import { Button } from '..';
import Logo from '../../assets/logo.svg';
import { HeaderProps } from '../../utilities/types';
import { VERTICAL_SCROLL_MENU_ITEM } from '../../utilities/constants';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const classMenuGSAP = [
  '#navbar-mobile-text-1',
  '#navbar-mobile-text-2',
  '#navbar-mobile-text-3',
  '#navbar-mobile-text-4',
]

const index: React.FC<HeaderProps> = ({ scrollToSection }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [scrollPercentage, setScrollPercentage] = useState(0);

  const handleScrollProgressBar = () => {
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const bodyHeight = document.body.offsetHeight;

    const scrollPercentage = (scrollPosition / (bodyHeight - windowHeight)) * 100;
    setScrollPercentage(scrollPercentage);
  };

  const handleToggleMenu = () => {
    setIsOpen(!isOpen);
  }

  const handleChooseMenu = (menu: string): void => {
    const section = document.getElementById(menu);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      handleToggleMenu()
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    }
    handleResize();

    const timeline = gsap.timeline();
    if (isOpen){
      const animate = classMenuGSAP.map((elementSelector, index) => {
        return gsap.from(elementSelector, {
          opacity: 0,
          x: 50,
          duration: 1,
          position: 0.2,
          ease: 'power2.out',
          delay: index * 0.1, 
        });
      });

      timeline.add(animate)
    }

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScrollProgressBar);

    return () => {
      window.removeEventListener('scroll', handleScrollProgressBar);
      window.removeEventListener('resize', handleResize)
    }
  }, [isOpen])
  
  return (
    <>
      {isMobile ? (
        <>
          <nav className='navbar-mobile'>
            <Logo id='header-mobile__logo' onClick={scrollToSection('hero')} style={{ cursor: 'pointer', width: '2rem' }} />
            <div className='navbar-mobile__hamburger-wrapper'>
              <label 
                className='navbar-mobile__hamburger'
                htmlFor="hamburger-toggle"
              >
                <input
                  onClick={handleToggleMenu} 
                  id='hamburger-toggle'
                  name='hamburger-toggle'
                  type='checkbox' 
                  checked={isOpen}
                />
              </label>
            </div>
          </nav>
          <div
            className='navbar-mobile__progressBar'
            style={{
              width: `${scrollPercentage}%`,
            }}
          />
          <div
            className={`navbar-mobile__menu ${isOpen ? '' : 'navbar-mobile__menu--closed'}`}
          >
            {VERTICAL_SCROLL_MENU_ITEM.map((item, idx) => {
              if (idx > 0){
                return (
                  <h1
                  id={`navbar-mobile-text-${idx}`}
                  className='navbar-mobile__menu-text'
                  key={item.menu}
                  onClick={() => handleChooseMenu(item.menu)}
                  >
                    {item.text}
                  </h1>
                );
              }
              })}
          </div>
      </>
      ) : ( 
        <nav className="navbar">
          <Logo id='header__logo' onClick={scrollToSection('hero')} style={{ cursor: 'pointer' }} />
          <Button id='header__button' textButton="Contact" scrollToSection={scrollToSection} />
        </nav>
        )
      }
    </>
  )
};

export default index;
