import React, { forwardRef, ForwardRefRenderFunction, useEffect } from 'react';
import './style.scss';
import { SingleProjectProps } from '../../utilities/types';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import ProjectContent from './ProjectContent'

gsap.registerPlugin(ScrollTrigger);

const index: ForwardRefRenderFunction<HTMLElement, SingleProjectProps> = (props, ref) => {
  const {
    header,
    body,
    badges,
    linkDemo,
    linkGithub,
    sectionId,
    scrollToSection,
  } = props;

  return (
    <>
      <section
        id={sectionId}
        className="horizontalSection background content-center"
      >
        <ProjectContent
          ref={ref}
          sectionId={sectionId}
          header={header}
          body={body}
          badges={badges}
          linkDemo={linkDemo}
          linkGithub={linkGithub}
          scrollToSection={scrollToSection}
        />
      </section>
    </>
  );
};

export default forwardRef(index);

