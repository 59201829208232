import React, { useState, useRef, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import './style.scss';
import {
  Header,
  Hero,
  About,
  Contact,
  Projects,
  Skills,
  VerticalScrollIndicator,
} from '../components';

gsap.registerPlugin(ScrollTrigger);

export const LandingPage = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const heroRef = useRef<HTMLElement>(null);
  const aboutRef = useRef<HTMLElement>(null);
  const skillsRef = useRef<HTMLElement>(null);
  const projectsRef = useRef<HTMLElement>(null);
  const contactRef = useRef<HTMLElement>(null);
  const [currentSection, setCurrentSection] = useState<string | undefined>(
    'hero',
  );
  const gsapContextRef = useRef<any | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const sectionOffsets = {
        hero: heroRef?.current?.offsetTop || 0,
        about: aboutRef?.current?.offsetTop || 963,
        skills: skillsRef?.current?.offsetTop || 1926,
        projects: projectsRef?.current?.parentElement?.offsetTop || 2890,
        contact: contactRef?.current?.offsetTop || 9194,
      };

      const tolerance = 2;
      const activeSection = Object.entries(sectionOffsets).find(
        ([section, offset]) => {
          return (
            scrollY + tolerance >= offset &&
            scrollY + tolerance < offset + window.innerHeight
          );
        },
      )?.[0];

      if (
        scrollY + tolerance >= sectionOffsets.projects &&
        scrollY + tolerance < sectionOffsets.contact
      ) {
        setCurrentSection('projects');
      } else {
        setCurrentSection(activeSection);
      }
    };

    window.addEventListener('scroll', handleScroll);

    const checkAndInitGSAP = () => {
      const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
      const viewportHeight = window.innerHeight;

      if (viewportWidth > 768 && viewportHeight > 500) {
        if (!gsapContextRef.current) {
          gsapContextRef.current = gsap.context(() => {
            gsap.context(() => {
              gsap.timeline({
                scrollTrigger: {
                  trigger: '#hero',
                  start: 'top top',
                  endTrigger: '#projects',
                  end: 'bottom bottom',
                  snap: {
                    snapTo: 1 / 3,
                    duration: { min: 0.25, max: 0.75 },
                    delay: 0.125,
                    ease: 'power1.inOut',
                  },
                },
              });
        
              gsap.timeline({
                scrollTrigger: {
                  trigger: '#contact',
                  start: 'top bottom+=1',
                  end: 'bottom bottom',
                  snap: {
                    snapTo: [0, 1],
                    duration: { min: 0.25, max: 0.75 },
                    delay: 0.125,
                    ease: 'power1.inOut',
                  },
                },
              });
            }, containerRef);
          }, containerRef);
        }
      } else {
        if (gsapContextRef.current) {
          gsapContextRef.current.kill();
          gsapContextRef.current = null;
        }
      }
    }

    checkAndInitGSAP();

    window.addEventListener('resize', checkAndInitGSAP)

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', checkAndInitGSAP);

      if (gsapContextRef.current) {
        gsapContextRef.current.kill();
      }
    };
  }, []);

  const scrollToSection = (sectionId: string) => () => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div id="container" ref={containerRef}>
        <Header scrollToSection={scrollToSection} />
        <VerticalScrollIndicator
          currentSection={currentSection}
          scrollToSection={scrollToSection}
        />
        <Hero ref={heroRef} />
        <About ref={aboutRef} />
        <Skills ref={skillsRef} />
        <Projects projectsRef={projectsRef} scrollToSection={scrollToSection} />
        <Contact ref={contactRef} />
      </div>
    </>
  );
};

export default LandingPage;
