import React, { useEffect, ForwardRefRenderFunction } from 'react';
import './style.scss';
import gsap from 'gsap';
import Lottie from 'lottie-web';
import scrollDownAnimation from '../../animation/scroll_down_animation_v2.json';

const animatedFixedElement = [
  ".navbar-mobile", 
  "#header-mobile__logo", 
  ".navbar-mobile__hamburger",
  "#header__logo", 
  "#header__button",
  ".nav-vertical",
  "#hero__scroll-down-animation"
];

const elementsToAnimate = [
  ".gradient-heading-text",
  ".hero__text-subhead",
  ".hero__text-body",
];

const HeroComponent: ForwardRefRenderFunction<HTMLElement, {}> = (_, ref) => {
  
  useEffect(() => {
    const scrollAnimation = document.getElementById(
      'hero__scroll-down-animation',
      ) as HTMLDivElement;
      const animate = Lottie.loadAnimation({
        container: scrollAnimation,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet',
        },
        animationData: scrollDownAnimation,
      });
      
    const timeline = gsap.timeline();
    
    const animateFixedElementsGSAP = gsap.from(
      animatedFixedElement, {
      duration: 1, 
      opacity: 0, 
      y: 50, 
      ease: 'power2.out', 
      position: 0
      }
    )
    
    const animateHeroSectionGSAP = elementsToAnimate.map((elementSelector, index) => {
      return gsap.from(elementSelector, {
        duration: 1,
        opacity: 0,
        y: 50,
        ease: 'power2.out',
        position: 0.2,
        delay: index * 0.15,
      });
    });

    timeline.add(animateFixedElementsGSAP)
            .add(animateHeroSectionGSAP)

    return () => {
      animate.destroy();
    };
  }, []);

  return (
    <section id="hero" className="background content-center" ref={ref}>
      <div className="hero__heading-wrapper">
        <h1 className="gradient-heading-text">YOSSY ADIRTA</h1>
        <div className="hero__sub-heading">
          <h2 className="hero__text-subhead">Software Developer</h2>
          <span className="hero__text-body">Based in Jakarta</span>
        </div>
      </div>
      <div className="hero__scroll-down-wrapper">
        <div id="hero__scroll-down-animation"></div>
      </div>
    </section>
  );
};

export default React.forwardRef(HeroComponent);