import AboutIllustration from '../../assets/aboutme.svg';
import React, { useEffect, ForwardRefRenderFunction } from 'react';
import './style.scss';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const animateElements: string[] = [
  ".about__header",
  ".about__body-text",
  "#about-svg",
];

const index: ForwardRefRenderFunction<HTMLElement, {}> = (_, ref: React.Ref<HTMLElement>) => {

  useEffect(() => {
    if (typeof ref === 'function' || !ref?.current) return;

    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    if (viewportWidth > 768 && viewportHeight > 500) {
      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: ref?.current,
          start: 'top center',
        }
      });

      const aboutAnimate = animateElements.map((elementSelector, index) => {
        return gsap.from(elementSelector, {
          opacity: 0,
          y: 50,
          duration: 1,
          position: 0.2,
          delay: index * 0.15,
        });
      });
      
      timeline.add(aboutAnimate);
    }
  }, []);

  return (
    <section id="about" className="background content-center" ref={ref}>
      <div className="about__wrapper">
        <div className="about__left-column">
          <h2 className="about__header">Hello, I'm Yossy</h2>
          <div className="about__body-text">
            <p>
              A Software Developer based in Jakarta with a passion for coding
              and design. Currently, I work as a Frontend Developer at an IT
              consultancy firm in a project with one of Indonesia's largest
              telecommunications providers.
            </p>
            <p>
              My journey into software development began in 2017 when I got the
              challenge to create a website for a campus organization. This
              initial step ignited a lasting passion for digital craftsmanship.
            </p>
            <p>
              My background lies in development, but I also have experience as a
              UX Designer, which has enriched my skill set and contributed to my
              proficiency in software development.
            </p>
            <p>
              Check out my{' '}
              <span>
                <a
                  href="https://www.linkedin.com/in/yossyadirta/"
                  target="_blank"
                >
                  LinkedIn
                </a>
              </span>{' '}
              to get to know more about me
            </p>
          </div>
        </div>
        <AboutIllustration 
          alt="About Illustration"
          loading="lazy"
        />
      </div>
    </section>
  );
};

export default React.forwardRef(index);

