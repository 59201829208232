import React, { ForwardRefRenderFunction, useEffect } from 'react';
import './style.scss';
import {
  DiHtml5,
  DiCss3,
  DiReact,
  DiSass,
  DiBootstrap,
  DiNodejs,
  DiVisualstudio,
} from 'react-icons/di';
import { IoLogoVue } from 'react-icons/io5';
import { SiSequelize, SiTailwindcss, SiExpress } from 'react-icons/si';
import { TbBrandNextjs } from 'react-icons/tb';
import { BiLogoPostgresql } from 'react-icons/bi';
import { FiFigma } from 'react-icons/fi';
import { techStackItem } from '../../utilities/types';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const techStackItems: techStackItem[] = [
  {
    icon: <DiHtml5 />,
    text: 'HTML',
  },
  {
    icon: <DiCss3 />,
    text: 'CSS',
  },
  {
    icon: <SiTailwindcss />,
    text: 'Tailwind CSS',
  },
  {
    icon: <DiReact />,
    text: 'React',
  },
  {
    icon: <IoLogoVue />,
    text: 'Vue',
  },
  {
    icon: <DiSass />,
    text: 'SASS',
  },
  {
    icon: <DiBootstrap />,
    text: 'Bootstrap',
  },
  {
    icon: <DiReact />,
    text: 'React Native',
  },
  {
    icon: <DiNodejs />,
    text: 'NodeJS',
  },
  {
    icon: <SiExpress />,
    text: 'Express',
  },
  {
    icon: <BiLogoPostgresql />,
    text: 'PostgreSQL',
  },
  {
    icon: <SiSequelize />,
    text: 'Sequelize',
  },
  {
    icon: <TbBrandNextjs />,
    text: 'NextJS',
  },
  {
    icon: <DiVisualstudio />,
    text: 'Visual Studio',
  },
  {
    icon: <FiFigma />,
    text: 'Figma',
  },
];

const elementsAnimate: string[] = [];

techStackItems.forEach((_, index) => elementsAnimate.push(`#tech-stack-${index+1}`));

const index: ForwardRefRenderFunction<HTMLElement, {}> = (_, ref: React.Ref<HTMLElement>) => {

  useEffect(() => {
    if (typeof ref === 'function' || !ref?.current) return;

    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    if (viewportWidth > 768 && viewportHeight > 500) {

      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: ref?.current,
          start: 'top center',
        }
      });

      const skillsBodyElements = [
        ".skills__header",
        ".skills__body"
      ];
      
      const skillsBodyGSAP = skillsBodyElements.map((elementSelector, index) => {
        return gsap.from(elementSelector, {
          opacity: 0,
          y: 50,
          duration: 1,
          position: 0.2,
          delay: index * 0.15,
        });
      });
      
      const techStackIconsGSAP = elementsAnimate.map((elementSelector, index) => {
        return gsap.from(elementSelector, {
          opacity: 0,
          y: 50,
          duration: 1,
          position: 0.2,
          ease: 'power2.out',
          delay: index * 0.05, 
        });
      });
    
      timeline.add(skillsBodyGSAP)
              .add(techStackIconsGSAP);
    }
  }, []);

  return (
    <section id="skills" className="background skills__container" ref={ref}>
      <div className="skills__wrapper">
        <h3 className="skills__header">Skills & Experience</h3>
        <p className="skills__body">
          Specializing in frontend development, I focus on crafting visually
          appealing and user-centered interfaces. While my main concentration
          remains frontend, I've also developed a genuine affinity for fullstack
          work.
        </p>
      </div>
      <div className="skills__tech-stack">
        {techStackItems.map((item, index) => (
          <div key={index} className="skills__tech-stack-icon" id={`tech-stack-${index+1}`}>
            {item.icon}
            <span className="skills__tech-stack-text">{item.text}</span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default React.forwardRef(index);
