import React, {ForwardRefRenderFunction, useEffect, useState} from 'react';
import './style.scss';
import { BsInstagram } from 'react-icons/bs';
import { MdOutlineMailOutline } from 'react-icons/md';
import { PiGithubLogoBold } from 'react-icons/pi';
import { FiLinkedin } from 'react-icons/fi';
import { socialMediaItem } from '../../utilities/types';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import gsap from 'gsap';

gsap.registerPlugin(ScrollTrigger);

const contactBodyElements = [
  ".contact__header",
  ".contact__body"
];
const elementsAnimate: string[] = [];

const socialMediaItems: socialMediaItem[] = [
  {
    icon: (
      <MdOutlineMailOutline style={{ fontSize: '3rem', marginTop: '-2px' }} />
    ),
    text: 'Email',
    url: 'mailto:yossyadirta@gmail.com',
    ariaLabel: 'Email: yossyadirta@gmail.com'
  },
  {
    icon: <FiLinkedin />,
    text: 'LinkedIn',
    url: 'https://www.linkedin.com/in/yossyadirta/',
    ariaLabel: 'LinkedIn: Yossy Adirta Soeryo Legowo'
  },
  {
    icon: <PiGithubLogoBold />,
    text: 'Github',
    url: 'https://github.com/yossyadirta',
    ariaLabel: 'Github: yossyadirta'
  },
  {
    icon: <BsInstagram />,
    text: 'Instagram',
    url: 'https://www.instagram.com/yossyadirta/',
    ariaLabel: 'Instagram: @yossyadirta'
  },
];

socialMediaItems.forEach((_, index) => {
  return elementsAnimate.push(`#social-media-items-${index + 1}`) 
});


const index: ForwardRefRenderFunction<HTMLElement, {}> = (_, ref: React.Ref<HTMLElement>) => {
  const [oneLineFooter, setOneLineFooter] = useState(true);

  useEffect(() => {
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  if (viewportWidth > 768 && viewportHeight > 500) {
    if (typeof ref === 'function') return;
    if (!ref?.current) return;
    
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: ref?.current,
        start: 'top center',
      }
    })
    
    const contactBodyGSAP = contactBodyElements.map((elementSelector, index) => {
      return gsap.from(elementSelector, {
        opacity: 0,
        y: 50,
        duration: 1,
        position: 0.2,
        delay: index * 0.1,
      });
    });
    const socialMediaItemsGSAP = elementsAnimate.map((elementSelector, index) => {
      return gsap.from(elementSelector, {
        opacity: 0,
        y: 50,
        duration: 1,
        position: 0.2,
        ease: 'power2.out',
        delay: index * 0.05
      })
    })
    
    const footerGSAP = ['.contact__footer'].map((elementSelector, index) => {
      return gsap.from(elementSelector, {
        opacity: 0,
        y: 50,
        duration: 1,
        position: 0.2
      })
    })
    setOneLineFooter(false);
    
    timeline.add(contactBodyGSAP)
    .add(socialMediaItemsGSAP)
    .add(footerGSAP);
  } else {
      setOneLineFooter(true)
    }
  }, []);
  
  return (
    <section id="contact" className="background" ref={ref}>
      <div className="contact__wrapper">
        <div className="contact__content">
          <p className="contact__header">Get In Touch</p>
          <p className="contact__body" id='contact__body__first_p'>
            Feel free to reach out and connect using the icons below. Stay in
            the loop whether it's for work inquiries, collaborations, or just to
            drop a friendly 'Hello'. Looking forward to hearing from you!</p>
          <div className="contact__social-media-icons">
            {socialMediaItems.map((item, index) => {
              return (
                <div key={index} id={`social-media-items-${index + 1}`}>
                  <a href={item.url} target="_blank" rel="noopener noreferrer" aria-label={item.ariaLabel}>
                    {item.icon}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="contact__footer">
        {oneLineFooter ?(
        <p>
          Designed in{' '}
          <span>
            {' '}
            <a href="https://www.figma.com/" target="_blank" rel="noopener noreferrer" aria-label='Figma'>
              Figma
            </a>
          </span>{' '}
          and translated into code through
          <span>
            {' '}
            <a href="https://code.visualstudio.com/" target="_blank" rel="noopener noreferrer" aria-label='VS Code'>
              VS Code
            </a>
          </span>{' '}
          Crafted with{' '}
          <span>
            {' '}
            <a href="https://www.gatsbyjs.com/" target="_blank" rel="noopener noreferrer" aria-label='Gatsby'>
              Gatsby
            </a>
          </span>{' '}
          and{' '}
          <span>
            {' '}
            <a href="https://sass-lang.com/" target="_blank" rel="noopener noreferrer" aria-label='SASS'>
              SASS
            </a>
          </span>
          , deployed with{' '}
          <span>
            {' '}
            <a href="https://vercel.com/" target="_blank" rel="noopener noreferrer" aria-label='Vercel'>
              Vercel
            </a>
          </span>{' '}
        </p>):(<> <p>
          Designed in{' '}
          <span>
            {' '}
            <a href="https://www.figma.com/" target="_blank" rel="noopener noreferrer" aria-label='Figma'>
              Figma
            </a>
          </span>{' '}
          and translated into code through
          <span>
            {' '}
            <a href="https://code.visualstudio.com/" target="_blank" rel="noopener noreferrer" aria-label='VS Code'>
              VS Code
            </a>
          </span>{' '}
        </p>
        <p>
          Crafted with{' '}
          <span>
            {' '}
            <a href="https://www.gatsbyjs.com/" target="_blank" rel="noopener noreferrer" aria-label='Gatsby'>
              Gatsby
            </a>
          </span>{' '}
          and{' '}
          <span>
            {' '}
            <a href="https://sass-lang.com/" target="_blank" rel="noopener noreferrer" aria-label='SASS'>
              SASS
            </a>
          </span>
          , deployed with{' '}
          <span>
            {' '}
            <a href="https://vercel.com/" target="_blank" rel="noopener noreferrer" aria-label='Vercel'>
              Vercel
            </a>
          </span>{' '}
        </p></>)}
      </div>
    </section>
  );
};

export default React.forwardRef(index);
