import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { SingleProjectProps } from '../../utilities/types';
import { Button } from '..';
import { StaticImage } from 'gatsby-plugin-image';
import { MdNavigateNext } from 'react-icons/md';

const ProjectContent: ForwardRefRenderFunction<HTMLElement, SingleProjectProps> = (props, ref) => {
  const {
    header,
    body,
    badges,
    linkDemo,
    linkGithub,
    sectionId,
    scrollToSection,
  } = props;

  const handleScrollToProject1 = () => {
    const scrollAmount = 100;
    window.scrollBy({ top: scrollAmount, behavior: 'smooth' });
  };

  if (sectionId === 'project-cover') {
    return (
      <div className='project-cover__wrapper'>
        <h3 className="project-cover__header">Projects</h3>
        <p className="project-cover__body">
          
          I’ve built various different projects to fit different aspects. If you
          want to see more example of my work than the ones showcased in this
          site,
          please 
          <span
            className="project-cover__change-section-contact"
            onClick={scrollToSection ? scrollToSection('contact') : undefined}
          >
            contact me!
          </span>
        </p>
        <div
          className="project-cover__change-section-project"
          onClick={handleScrollToProject1}
        >
          <p>See Projects</p>
          <span>
            <MdNavigateNext style={{ marginTop: '0.2rem' }} />
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="project-wrapper">
          <div className="project__left-column">
            <h1 className="project__left-column__header">{header}</h1>
              <p className="project__body">{body}</p>
            <div className="project__left-column__badges">
              {badges.map((badge, index) => (
                <div key={index} className="project__left-column__badge-item">
                  <span>{badge}</span>
                </div>
              ))}
            </div>
            <div className="project__left-column__buttons">
              <Button id={`btn-demo-${sectionId}`} textButton="Live Demo" icon="demo" linkTo={linkDemo} />
              <Button
                id={`btn-github-${sectionId}`}
                textButton="View Code"
                icon="github"
                linkTo={linkGithub}
              />
            </div>
          </div>
          <ProjectImage sectionId={sectionId} />
        </div>
      </>
    );
  }
};

const ProjectImage: React.FC<{ sectionId: string }> = ({ sectionId }) => {
  let content;

  switch (sectionId) {
    case 'project-1':
      content = (
        <div className="project__right-column">
          <StaticImage
            src="../../assets/project-image-1.png"
            alt="Makans"
            className="project__image-1"
            loading="lazy"
            placeholder="blurred"
          />
        </div>
      );
      break;
    case 'project-2':
      content = (
        <div className="project__right-column-project-2">
          <StaticImage
            src="../../assets/project-image-2.png"
            alt="Kitchef"
            className="project__image-2"
            loading="lazy"
            placeholder="blurred"
          />
        </div>
      );
      break;
    case 'project-3':
      content = (
        <div className="project__right-column">
          <StaticImage
            src="../../assets/project-image-3.png"
            alt="Jobhunt"
            className="project__image-3"
            loading="lazy"
            placeholder="blurred"
          />
        </div>
      );
      break;
    default:
      content = <div className="project__right-column"></div>;
  }

  return content;
};

export default forwardRef(ProjectContent);
