import React from 'react';
import './style.scss';
import { VerticalScrollProps } from '../../utilities/types';
import { VERTICAL_SCROLL_MENU_ITEM } from '../../utilities/constants';

const index: React.FC<VerticalScrollProps> = (props) => {
  const { currentSection, scrollToSection } = props;

  return (
    <ul className="nav-vertical">
      {VERTICAL_SCROLL_MENU_ITEM.map((item) => {
        return (
          <li
            key={item.menu}
            className="nav-vertical-item"
            onClick={scrollToSection ? scrollToSection(item.menu) : undefined}
          >
            <span
              className={`nav-vertical-text ${
                currentSection === item.menu ? 'active' : ''
              }`}
            >
              {item.text}
            </span>
            <div
              className={`nav-vertical-dot ${
                currentSection === item.menu ? 'active' : ''
              }`}
            ></div>
          </li>
        );
      })}
    </ul>
  );
};

export default index;
