import React, { useLayoutEffect, useState, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import './style.scss';
import { Project } from '..';
import { ProjectsProps } from '../../utilities/types';
import { DATA_PROJECTS } from '../../utilities/constants';
import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md';

gsap.registerPlugin(ScrollTrigger);

const projectCoverElements = [
  '.project-cover__header',
  '.project-cover__body',
  '.project-cover__change-section-project'
];

const index: React.FC<ProjectsProps> = ({ projectsRef, scrollToSection }) => {
  const [showNavigation, setShowNavigation] = useState(false);
  const gsapContextRef = useRef<any | null>(null);

  useLayoutEffect(() => {
    const horizontalScrollData = gsap.utils.toArray('.horizontalSection');
    const trigger = projectsRef.current;

    const checkAndInitGSAP = () => {
      const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

      const viewportHeight = window.innerHeight;
      if (viewportWidth > 768 && viewportHeight > 500) {
        if (!gsapContextRef.current) {
          gsapContextRef.current = gsap.to(horizontalScrollData, {
            xPercent: -100 * (horizontalScrollData.length - 1),
            ease: 'none',
            scrollTrigger: {
              trigger: trigger,
              scrub: 1,
              pin: true,
              snap: 1 / (horizontalScrollData.length - 1),
              onUpdate: self => {
                const hasScrolledPastFirstSection = self.progress > 1 / horizontalScrollData.length;
                setShowNavigation(hasScrolledPastFirstSection);
              },
              end: () => `+=${trigger ? trigger.offsetWidth : 0}`,
            },
          });
        }

        const timeline = gsap.timeline({
          scrollTrigger: {
            trigger: projectsRef?.current,
            start: 'top center',
          }
        });
    
        const projectCoverGSAP = projectCoverElements.map((elementSelector, index) => {
          return gsap.from(elementSelector, {
            opacity: 0,
            y: 50,
            duration: 1,
            position: 0.2,
            delay: index * 0.15,
          });
        });
        
        timeline.add(projectCoverGSAP);
      } else {
        if (gsapContextRef.current) {
          gsapContextRef.current.kill();
          gsapContextRef.current = null;
        }
      }
    }
    checkAndInitGSAP();

    window.addEventListener('resize', checkAndInitGSAP)
    return () => {
      window.removeEventListener('resize', checkAndInitGSAP);
      if (gsapContextRef.current) {
        gsapContextRef.current.kill();
      }
    };
  }, []);

  const handleScrollHoritontal = (direction: string) => () => {
    let scrollAmount: number = 0;
    if (direction === 'right') scrollAmount = -100;
    if (direction === 'left') scrollAmount = 100;

    window.scrollBy({ top: scrollAmount, behavior: 'smooth' });
  };

  return (
    <section id="projects" ref={projectsRef} className="background">
        
      {DATA_PROJECTS.map((project, index) => (
        <Project
        scrollToSection={scrollToSection}
        key={index}
        ref={projectsRef}
        sectionId={project.id}
        header={project.header}
        body={project.body}
        badges={project.badges}
        linkDemo={project.linkDemo}
        linkGithub={project.linkGithub}
        />
        ))}
        {showNavigation && (
        <div className="project__prev-next__wrapper">
          <MdNavigateBefore
            className="project__prev"
            onClick={handleScrollHoritontal('right')}
          />
          <MdNavigateNext
            className="project__next"
            onClick={handleScrollHoritontal('left')}
          />
        </div>
      )}
    </section>
  );
};

export default index;
